import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, {
    ButtonItem,
    SimpleItem,
    ColCountByScreen,
    EmptyItem,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
    Label,
} from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { PatternRule } from 'devextreme-react/data-grid'
import { useDispatch, useSelector } from 'react-redux'
import inventoryService from '../../../../services/pages/store/inventory/inventoryServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from '../../../../services/util-services'
import { SelectBox } from 'devextreme-react'
import { selectItemById, updateInventory } from '../../../../redux/store/features/inventory'
import { useMediaQuery } from '@mui/material'

const UpdateInventoryForm = (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit } = useForm()
    const { inventories, loading, error } = useSelector((state) => state.inventories)
    const inventory = selectItemById(inventories, props.inventoryId)
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [fkInvCatId, setFkInvCatId] = useState(inventory.fkInvCatId)
    const [fkInvSubCatId, setFkInvSubCatId] = useState(inventory.fkInvSubCatId)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const displayOrderEditorOptions = {
        showSpinButtons: 'true',
    }

    const displayTotalStockEditorOptions = {
        showSpinButtons: 'true',
        readOnly: true,
    }

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Inventory data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/inventory')
        }
    }, [loading])

    useEffect(() => {
        setFkInvCatId(inventory.fkInvCatId)
        setFkInvSubCatId(inventory.fkInvSubCatId)
    }, [inventory])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const categoryLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getCategoryLookupList({ categoryName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [fkInvCatId]
    )

    const categoryLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getCategoryById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [fkInvCatId]
    )

    const CategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'invCategoryId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await categoryLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await categoryLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onCategoryValueChanged = useCallback(
        (e) => {
            setFkInvCatId(e.value)
            if (e.value !== fkInvCatId) setFkInvSubCatId(null)
        },
        [fkInvCatId]
    )

    const subCategoryLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getSubCategoryLookupList({
                    categoryId: fkInvCatId,
                    subCategoryName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [fkInvCatId]
    )

    const subCategoryLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getSubCategoryById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [fkInvSubCatId]
    )

    const SubCategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'invSubCategoryId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await subCategoryLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await subCategoryLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        [fkInvCatId]
    )
    const onSubCategoryValueChanged = useCallback(
        (e) => {
            setFkInvSubCatId(e.value)
        },
        [fkInvSubCatId]
    )

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/inventory'),
    }

    const validationRules = {
        inventoryName: [
            { type: 'required', message: 'Inventory Name is required.' },
            { type: 'stringLength', min: 4, message: 'Inventory Name must have at least 4 symbols.' },
        ],
        displayOrder: [{ type: 'range', min: 0, message: 'Display Order should have positive value.' }],
        salePrice: [{ type: 'required', message: 'Sale Price is required.' }],
        totalStock: [{ type: 'required', message: 'Total Stock quantity is required.' }],
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /^\+\d{10,15}$/,
        },
    }
    const phonePattern = /^\+\d{10,15}$/

    const onUpdateInventory = useCallback(() => {
        setApiCallInProgress(true)
        dispatch(
            updateInventory({
                ...props.inventoryData,
                invClassId: props.inventoryId,
                fkInvCatId: fkInvCatId,
                fkInvSubCatId: fkInvSubCatId,
            })
        )
    }, [props.inventoryData, fkInvCatId, fkInvSubCatId])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Update Inventory Item
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(onUpdateInventory)}>
                    <Form
                        mode='form'
                        formData={props.inventoryData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem>
                            <TabbedItem>
                                <TabPanelOptions deferRendering={false} />
                                <Tab title='Inventory'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem>
                                            <GroupItem caption='Basic Info' cssClass='form-group-item'>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <GroupItem>
                                                        <ColCountByScreen lg={2} md={1} sm={1} xs={1} />
                                                        <SimpleItem dataField='needCustomer' editorType='dxCheckBox'>
                                                            <Label text='Require Customer Info' />
                                                        </SimpleItem>
                                                        <SimpleItem dataField='isActive' editorType='dxCheckBox'>
                                                            <Label text='Item is Active' />
                                                        </SimpleItem>
                                                        <SimpleItem
                                                            dataField='isRawMaterialItem'
                                                            editorType='dxCheckBox'>
                                                            <Label text='This is a raw material item' />
                                                        </SimpleItem>
                                                        <SimpleItem
                                                            dataField='isVirtualInventory'
                                                            editorType='dxCheckBox'>
                                                            <Label
                                                                text='This is a virtual inventory item'
                                                                location={'left'}
                                                            />
                                                        </SimpleItem>
                                                    </GroupItem>
                                                    <SimpleItem dataField='inventoryNo'>
                                                        <Label text='Inventory Code' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='inventoryName'
                                                        validationRules={validationRules.inventoryName}
                                                    />
                                                    <SimpleItem dataField='sku'>
                                                        <Label text='Stock Keeping Unit (SKU)' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='displayOrder'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                        validationRules={validationRules.displayOrder}
                                                    />
                                                    <SimpleItem dataField='barcode' />
                                                    <SimpleItem dataField='cartName' />
                                                    <SimpleItem dataField='printName' />
                                                    <SimpleItem dataField='description' />
                                                </GroupItem>
                                            </GroupItem>
                                            <GroupItem caption='Unit of measure' cssClass='form-group-item'>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='fkSaleUomId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.UnitOfMeasureEditorOptions}>
                                                        <Label text='Sale Uom' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='fkStockUomId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.UnitOfMeasureEditorOptions}>
                                                        <Label text='Stock Uom' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='fkOrderUomId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.UnitOfMeasureEditorOptions}>
                                                        <Label text='Order Uom' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <GroupItem caption='Classification' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='fkInvTypeId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.inventoryTypeEditorOptions}>
                                                        <Label text='Inventory Type' />
                                                    </SimpleItem>

                                                    <SimpleItem>
                                                        <Label text='Inventory Category' />
                                                        <SelectBox
                                                            dataSource={CategoryStore}
                                                            displayExpr='categoryName'
                                                            valueExpr='inventoryCategoryId'
                                                            searchEnabled={true}
                                                            showClearButton={true}
                                                            defaultValue={inventory.fkInvCatId}
                                                            value={fkInvCatId}
                                                            onValueChanged={onCategoryValueChanged}></SelectBox>
                                                    </SimpleItem>
                                                    <SimpleItem>
                                                        <Label text='Inventory Sub Category' />
                                                        <SelectBox
                                                            dataSource={SubCategoryStore}
                                                            displayExpr='subCatName'
                                                            valueExpr='invSubCategoryId'
                                                            searchEnabled={true}
                                                            //defaultValue={inventory.fkInvSubCatId}
                                                            showClearButton={true}
                                                            value={fkInvSubCatId}
                                                            onValueChanged={onSubCategoryValueChanged}></SelectBox>
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='fkSaleChartAccountId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.chartAccountEditorOptions}>
                                                        <Label text='Sale Chart of Account' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='fkPurchaseChartAccountId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.chartAccountEditorOptions}>
                                                        <Label text='Purchase Chart of Account' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='fkStockChartAccountId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.chartAccountEditorOptions}>
                                                        <Label text='Stock Chart of Account' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='fkDamagedChartAccountId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.chartAccountEditorOptions}>
                                                        <Label text='Damage Account' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                            <GroupItem caption='Definition' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='modelNumber'
                                                        editorOptions={phoneEditorOptions}>
                                                        <PatternRule
                                                            message='phone number must start with + and contain 10 to 15 digits'
                                                            pattern={phonePattern}
                                                        />
                                                        <Label text='Phone Number' />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='supplierBarcode' />
                                                    <SimpleItem dataField='supplierSku'>
                                                        <Label text='Supplier Stock Keeping Unit' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='supplierIds'
                                                        editorType='dxTagBox'
                                                        editorOptions={props.multiSupplierEditorOptions}>
                                                        <Label text='Multi Suppliers' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                                <Tab title=' Price'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem>
                                            <GroupItem caption='Sale Price' cssClass='form-group-item'>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='retailPrice'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                    />
                                                    <SimpleItem
                                                        dataField='salePrice'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                        validationRules={validationRules.salePrice}
                                                    />
                                                    <SimpleItem
                                                        dataField='fkChartTaxSaleId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.chartTaxEditorOptions}>
                                                        <Label text='Sale Chart Tax' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='salePriceDiscountRate'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                    />
                                                    <SimpleItem
                                                        editorType='dxSelectBox'
                                                        dataField='salePriceDiscountMode'
                                                        editorOptions={props.DiscountModeEditorOptions}
                                                    />
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <GroupItem caption='Wholesale Price' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='wholesalePrice'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}>
                                                        <Label text='Wholesale Price' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='wholesalePriceDiscountRate'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}>
                                                        <Label text='Wholesale Price Discount Rate' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        editorType='dxSelectBox'
                                                        dataField='wholesalePriceDiscountMode'
                                                        editorOptions={props.DiscountModeEditorOptions}>
                                                        <Label text='Wholesale Price Discount Mode' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                            <GroupItem caption='Purchase Price' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='purchasePrice'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                    />
                                                    <SimpleItem
                                                        dataField='fkChartTaxPurchaseId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={displayOrderEditorOptions}>
                                                        <Label text='Purchase Chart Tax' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='purchasePriceDiscountRate'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                    />
                                                    <SimpleItem
                                                        editorType='dxSelectBox'
                                                        dataField='purchasePriceDiscountMode'
                                                        editorOptions={props.DiscountModeEditorOptions}
                                                    />
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                                <Tab title='Stock Tracking'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem>
                                            <GroupItem caption='Stock Quantity' cssClass='form-group-item'>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='totalStock'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayTotalStockEditorOptions}
                                                        validationRules={validationRules.totalStock}>
                                                        <Label text='Total Stock Qty' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='qtyOnOrder'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                    />
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <GroupItem caption='Quantity Details' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='boxQuantity'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}
                                                    />
                                                    <SimpleItem
                                                        dataField='minQty'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}>
                                                        <Label text='Minimum Quantity' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='minOrderQty'
                                                        editorType='dxNumberBox'
                                                        editorOptions={displayOrderEditorOptions}>
                                                        <Label text='Minimum Order Quantity' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                                <Tab title='Loyalty'>
                                    <EmptyItem />
                                    <GroupItem caption='Loyalty Points' cssClass='form-group-item '>
                                        <ColCountByScreen lg={3} md={3} sm={1} xs={1} />
                                        <GroupItem />
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <EmptyItem />
                                            <SimpleItem dataField='loyaltyPoints' editorType='dxNumberBox' />
                                            <EmptyItem />
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateInventoryForm
