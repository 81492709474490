import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { buildFilter } from 'app/utils/appHelpers'
import { DropDownBox } from 'devextreme-react'
import DataGrid, { FilterRow, Paging, Selection } from 'devextreme-react/data-grid'
import { useSnackbar } from 'notistack'
import { Divider } from '@mui/material'
import formatCurrency, { formatCurrencyForm } from 'app/utils/components/formatCurrency/formatCurrency'
import { Validator, RequiredRule } from 'devextreme-react/validator'
import { useMediaQuery } from '@mui/material'

const UpdateDraftSuppliersPaymentForm = (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const supplierDropDownRef = useRef()
    const { loading } = useSelector((state) => state.suppliers)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/suppliers/draftsupplierspayments'),
    }

    const gridColumns = [
        { dataField: 'supplierCode' },
        { dataField: 'firstName', caption: 'Name' },
        {
            dataField: 'balanceAmount',
            caption: 'Balance',
            format: (data) => formatCurrency(data),
            allowFiltering: false,
        },
    ]

    const gridBoxDisplayExpr = useCallback((item) => {
        const balanceAmounts = item?.balanceAmount || 0
        const balanceAmount = balanceAmounts >= 0 ? balanceAmounts : `${Math.abs(balanceAmounts)}` // Avoid - minus value
        return item && `${item.supplierCode}, ${item.firstName + ' ' + item.lastName} , ${balanceAmount}`
    }, [])

    const supplierStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    loadMode: 'processed',
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = { skip: 0, take: 10 }
                        if (loadOptions?.filter) {
                            const filter = buildFilter(loadOptions.filter)
                            searchTerm = { ...searchTerm, ...filter }
                        }
                        return await supplierList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (utilServices.isNullOrUndefined(key) || key === '') {
                            return {}
                        }
                        return await supplierById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const supplierList = useCallback(async (filter) => {
        try {
            return await supplierService.getSupplierList(filter)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const syncDataGridSelection = useCallback((e) => {
        setGridBoxValue(e.value)
        if (supplierDropDownRef.current?.instance) {
            supplierDropDownRef.current.instance.focus()
        }
    }, [])

    const onGridBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
            setIsGridBoxOpened(e.value)
        }
    }, [])

    const [gridBoxValue, setGridBoxValue] = useState([''])
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false)
    const dataGridOnSelectionChanged = useCallback(() => {
        setIsGridBoxOpened(false)
    }, [])

    const useSubmitBehaviors =
        (gridBoxValue.length > 0 && gridBoxValue[0] !== '') || props?.supplierPaymentData?.fkSupplierId

    const dataGridRender = useCallback(
        () => (
            <DataGrid
                dataSource={supplierStore}
                columns={gridColumns}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={dataGridOnSelectionChanged}
                height='100%'
                remoteOperations={true}>
                <Selection mode='single' />
                <Paging enabled={false} pageSize={10} />
                <FilterRow visible={true} applyFilter='auto' />
            </DataGrid>
        ),
        [gridBoxValue, dataGridOnSelectionChanged]
    )

    const CurrencyEditorOptions = {
        format: (data) => {
            const positivePattern = '#0.##'
            const negativePattern = '(#0.##)'
            const pattern = data < 0 ? negativePattern : positivePattern
            return formatCurrencyForm(data, pattern)
        },
    }

    const validationRules = {
        paymentDate: [{ type: 'required', message: 'Payment Date is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Update Supplier Payment
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <div>
                    <form onSubmit={handleSubmit(props.updateDraftSuppliersPayment)}>
                        <Form
                            readOnly={props.supplierPaymentData.paymentStatus === 'Approved'}
                            mode='form'
                            formData={props.supplierPaymentData}
                            showColonAfterLabel={false}
                            showValidationSummary={false}
                            labelLocation={labelLocation}>
                            <GroupItem cssClass='form-group-item' caption='Supplier Payment Details'>
                                <ColCountByScreen lg={2} md={1} sm={1} xs={1} />
                                <GroupItem cssClass='form-group-item-margin'>
                                    <EmptyItem />
                                    <SimpleItem dataField='fkSupplierId'>
                                        <Label text='Supplier' />
                                        <DropDownBox
                                            ref={supplierDropDownRef}
                                            value={props?.supplierPaymentData?.fkSupplierId}
                                            opened={isGridBoxOpened}
                                            valueExpr='supplierId'
                                            deferRendering={false}
                                            inputAttr={{ 'aria-label': 'Owner' }}
                                            displayExpr={gridBoxDisplayExpr}
                                            placeholder='Select a value...'
                                            dataSource={supplierStore}
                                            readOnly={true}
                                            onValueChanged={syncDataGridSelection}
                                            onOptionChanged={onGridBoxOpened}
                                            contentRender={dataGridRender}>
                                            <Validator>
                                                <RequiredRule message='Supplier is required' />
                                            </Validator>
                                        </DropDownBox>
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='paymentDate'
                                        editorType='dxDateBox'
                                        validationRules={validationRules.paymentDate}
                                    />
                                    <GroupItem>
                                        <Divider />
                                    </GroupItem>
                                    <SimpleItem
                                        dataField='fkCashChartAccountId'
                                        editorType='dxSelectBox'
                                        editorOptions={props.chartAccountEditorOptions}>
                                        <Label text='Cash Chart Account' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='cashAmount'
                                        editorType='dxNumberBox'
                                        editorOptions={CurrencyEditorOptions}
                                    />
                                    <GroupItem>
                                        <Divider />
                                    </GroupItem>
                                    <SimpleItem
                                        dataField='fkCreditChartAccountId'
                                        editorType='dxSelectBox'
                                        editorOptions={props.chartAccountEditorOptions}>
                                        <Label text='Credit Chart Account' />
                                    </SimpleItem>

                                    <SimpleItem
                                        dataField='creditCardAmount'
                                        editorType='dxNumberBox'
                                        editorOptions={CurrencyEditorOptions}
                                    />
                                </GroupItem>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <EmptyItem />
                                    <SimpleItem
                                        dataField='fkOtherChartAccountId'
                                        editorType='dxSelectBox'
                                        editorOptions={props.chartAccountEditorOptions}>
                                        <Label text='Other Chart Account' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='othersAmount'
                                        editorType='dxNumberBox'
                                        editorOptions={CurrencyEditorOptions}
                                    />
                                    <GroupItem>
                                        <Divider />
                                    </GroupItem>
                                    <SimpleItem dataField='paymentReferenceNo'>
                                        <Label text='Payment Reference No' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <EmptyItem />
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                                <ButtonItem
                                    buttonOptions={{
                                        disabled: loading || props.supplierPaymentData.paymentStatus === 'Approved',
                                        icon: loading ? 'refresh' : 'check',
                                        useSubmitBehavior: useSubmitBehaviors,
                                        text: 'Save',
                                        type: 'default',
                                        width: 120,
                                    }}
                                    horizontalAlignment='right'
                                />
                                <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateDraftSuppliersPaymentForm
