import React, { useEffect, useState } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import {
    CustomPaging,
    DataTypeProvider,
    EditingState,
    PagingState,
    SelectionState,
    SortingState,
} from '@devexpress/dx-react-grid'
import { Alert, Paper, Stack } from '@mui/material'
import {
    Grid,
    PagingPanel,
    Table,
    TableEditRow,
    TableHeaderRow,
    TableInlineCellEditing,
    TableSelection,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { getPermissionList, updatePermission } from 'app/redux/store/features/permissions'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Checkbox from 'react-custom-checkbox'
import * as Icon from 'react-icons/fi'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'

const PermissionGrid = ({ handleError }) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const [enableButton, setEnabledButton] = useState(true)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const {
        permissions,
        updateInProgress,
        loading: permissionLoading,
        error: permissionError,
    } = useSelector((state) => state.permissions)

    const { userTypeConfig, loading: userLoading, error: userError } = useSelector((state) => state.users)
    const getRowId = (row) => row.permissionId
    const [CheckBoxColumns] = useState(['enabled'])
    const [sorting, setSorting] = useState([{ columnName: 'enabled', direction: 'asc' }])
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 50
    const [permission, setPermission] = useState({ data: [], totalCount: 0 })

    useEffect(() => {
        if (!apiCallInProgress || userLoading) return
        setApiCallInProgress(false)

        if (userError) handleError(userError)
    }, [userError, userLoading])

    useEffect(() => {
        if (!apiCallInProgress || permissionLoading) return
        setApiCallInProgress(false)

        if (permissionError) handleError(permissionError)
    }, [permissionLoading, permissionError])

    useEffect(() => {
        if (!apiCallInProgress || updateInProgress) return

        reloadPermissions()
        hideDialogAndRefreshUserList()
    }, [updateInProgress])

    useEffect(() => {
        setApiCallInProgress(true)
        if (userTypeConfig.id || userTypeConfig.id === 0) {
            setEnabledButton(true)
            dispatch(
                getPermissionList({
                    userType: userTypeConfig.id,
                    skip: 0,
                    take: pageSize,
                })
            )
        }
        setCurrentPage(0)
    }, [userTypeConfig.id, dispatch])

    useEffect(() => {
        setPermission({ ...permissions })
    }, [permissions])

    const CheckBoxFormatter = ({ value }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox
                    icon={<Icon.FiCheck size={20} />}
                    size={20}
                    borderColor={'#808080'}
                    checked={value}
                    disabled={userTypeConfig.readOnly}
                />
            </div>
        )
    }

    const CheckBoxEditor = ({ value, onValueChange, autoFocus, onBlur }) => {
        const handleChange = (selectedOption) => {
            onValueChange(selectedOption)
            setTimeout(() => onBlur())
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox
                    icon={<Icon.FiCheck size={20} />}
                    size={20}
                    checked={value}
                    borderColor={'#808080'}
                    disabled={userTypeConfig.readOnly}
                    onChange={(e) => handleChange(e)}
                    autoFocus={autoFocus}
                    onBlur={onBlur}
                />
            </div>
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={permissionLoading} {...props} />

    const CheckBoxTypeProvider = (props) => {
        return <DataTypeProvider formatterComponent={CheckBoxFormatter} editorComponent={CheckBoxEditor} {...props} />
    }

    const commitChanges = ({ changed }) => {
        if (changed) {
            setEnabledButton(false)
            const updatedPermissionsData = permission.data.map((row) => {
                const changedRow = changed[row.permissionId]
                return changedRow ? { ...row, ...changedRow } : row
            })
            setPermission((prevChanges) => {
                return {
                    ...prevChanges,
                    data: updatedPermissionsData,
                }
            })
        }
    }

    const onUpdatePermission = () => {
        const updatedPermission = { data: [] }
        permissions.data.map((data, index) => {
            if (permission.data[index].enabled !== permissions.data[index].enabled)
                updatedPermission.data.push({
                    userTypeId: permission.data[index].userTypeId,
                    permissionId: permission.data[index].permissionId,
                    enabled: permission.data[index].enabled,
                })
        })
        setApiCallInProgress(true)
        dispatch(updatePermission(updatedPermission))
    }

    const reloadPermissions = () => {
        dispatch(
            getPermissionList({
                userType: userTypeConfig.id,
                skip: 0,
                take: pageSize,
            })
        )
        setEnabledButton(true)
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { skip: pageSize * pageNumber, take: pageSize }
        reloadPermissions(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        reloadPermissions(currentFilter)
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const Cell = (props) => {
        let style = {
            paddingTop: 10,
            paddingBottom: 10,
        }
        if (props.column.name === 'enabled')
            style = {
                ...style,
                textAlign: 'center',
            }
        else {
            style = {
                ...style,
                textAlign: 'left',
            }
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const handleSaveChanges = () => {
        showDialog({
            variant: 'confirm',
            title: 'This will update the selected permissions, do you want to continue?',
            onYes: () => {
                onUpdatePermission()
            },
            onNo: hideDialog,
        })
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (permissionError) {
            enqueueSnackbar(permissionError.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Permissions data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const columns = [
        { name: 'enabled', title: 'Enabled' },
        { name: 'permissionName', title: 'Permission Name' },
    ]

    const columnWidths = [
        { columnName: 'enabled', width: '50%' },
        { columnName: 'permissionName', width: '50%' },
    ]

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        fontSize={'large'}
                        onClick={() => reloadPermissions()}
                        sx={{
                            mb: -1.5,
                            mr: 1,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Permissions
                </Typography>
            }
            action={
                <Stack direction={'row'} spacing={1} sx={{ mt: -3 }}>
                    <Tooltip title='Save Changes'>
                        <IconButton
                            disabled={enableButton}
                            aria-label='save'
                            sx={{ color: 'common.white' }}
                            onClick={handleSaveChanges}>
                            <SaveIcon fontSize={'large'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Discard Changes'>
                        <IconButton
                            disabled={enableButton}
                            sx={{ color: 'common.white' }}
                            onClick={() => reloadPermissions()}>
                            <ClearIcon fontSize={'large'} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
                pl: 1,
            }}>
            {userTypeConfig.readOnly && (
                <Stack
                    sx={{
                        width: 0.25,
                        zIndex: 1,
                        position: 'absolute',
                    }}>
                    <Alert severity='warning' sx={{ width: 350, m: 1, p: 0, pl: 1 }}>
                        {' '}
                        No modifications allowed for this permission.
                    </Alert>
                </Stack>
            )}
            <Paper style={{ position: 'relative', marginTop: 50 }}>
                <Grid rows={permission.data} columns={columns} getRowId={getRowId}>
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={permissions.totalCount} />
                    <Table
                        cellComponent={Cell}
                        columnExtensions={columnWidths}
                        noDataCellComponent={NoDataCellWithLoadingType}
                    />
                    <TableHeaderRow showSortingControls cellComponent={Cell} />
                    <CheckBoxTypeProvider for={CheckBoxColumns} />
                    <EditingState onCommitChanges={commitChanges} editingColumns={['enabled']} />
                    <TableInlineCellEditing startEditAction={'click'} selectTextOnEditStart={false} />
                    <TableEditRow />
                    <SelectionState />
                    <TableSelection selectByRowClick showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            </Paper>
        </JumboCardQuick>
    )
}

export default PermissionGrid
